<template>
  <main frame class="prod">
    <p-section class="hero">
      <div class="holder" v-prx:sticky>
        <div class="bg" v-prx:progress="{ r:[3,6], o:[0,1] }"></div>
        <h2 v-prx:progress="{ r:[3,6], o:[1,0] }">FIT 프레임</h2>
        <p v-prx:progress="{ r:[3,6], o:[1,0] }">공간의 기반</p>
        <img src="/img/cartridge/frame-hero.png" alt="" v-prx:progress="{
            MS: { r:[4,10], ty:[0,-180], o:[1,0] },
            TL: { r:[4,10], ty:[0,-500], o:[1,0] }
        }">
        <div class="overview" v-prx:progress="{ r:[6,10], o:[0,1], ty:[80,0] }">
          <p v-prx:outro="{ r:[1,12], o:[1,0], ty:[0,160] }">FIT 프레임</p>
          <p v-prx:outro="{ r:[1,12], o:[1,0], ty:[0,160] }">
            플랫폼의 기반이 되는 FIT 프레임입니다. 건물의 외벽을 견고하게 구성하며 다양한 카트리지를 편리하게 설치하고 교체할 수 있도록 합니다.</p>
        </div>
      </div>
    </p-section>
    <p-section class="feature">
      <div class="holder" v-prx:sticky>
        <ul v-prx:progress="{ r:[1,11], pan:[0,100] }">
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/frame-feature-ico-1.svg" alt="" class="ico">
              <p>직관적인 <br>서랍식 개폐 구조</p>
              <p>FIT 프레임은 서랍처럼 여닫을 수 있는
                단순한 구조로 제작되었습니다. 쉽고
                빠르게 사용자 맞춤형 공간을 마련합니다.</p>
              <div class="video">
                <video src="/img/cartridge/frame-feature-video-1.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/frame-feature-video-1.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/frame-feature-ico-2.svg" alt="" class="ico">
              <p>유연한 <br>카트리지 교체</p>
              <p>사용자 입장에서 설계된 FIT 프레임을
                통해 다양한 카트리지를 편리하게
                설치하고 교체할 수 있습니다.</p>
              <div class="video">
                <video src="/img/cartridge/frame-feature-video-2.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/frame-feature-video-2.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/frame-feature-ico-3.svg" alt="" class="ico">
              <p>건물 맞춤 <br>크기 변경</p>
              <p>프레임의 높이와 너비를 건물의 크기에
                맞춰 변경할 수 있어, 어떤 공간이든 FIT 플랫폼을 적용할 수 있습니다.</p>
              <div class="video">
                <video src="/img/cartridge/frame-feature-video-3.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/frame-feature-video-3.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </p-section>
    <p-section class="structure">
      <div class="holder" v-prx:sticky>
        <h3>변화에 대응하는 <br class="hidden-tp-up">모듈형 프레임</h3>
        <img src="/img/cartridge/frame-structure.png" alt="">
        <p>모듈형 프레임을 통해 공간의 크기와 기능을 쉽게 조절할 수 있어, 변화와 요구에 빠르고 유연하게 대응할 수 있습니다.</p>
      </div>
    </p-section>
    <section class="spec">
      <div class="inner-wrap">
        <h3>제품 사양</h3>
        <ul>
          <li>
            <img src="/img/cartridge/frame-spec.png">
            <p>FIT 프레임</p>
            <dl>
              <dt>기본 사양</dt>
              <dd><b>높이</b> 4,500mm</dd>
              <dd><b>너비</b> 1,200mm</dd>
              <dd><b>깊이</b> 1,200mm</dd>
              <dd><b>무게</b> 800kg</dd>
            </dl>
            <dl>
              <dt>Performance Mock Up Test PASS</dt>
              <dd><b>열순환 시험</b> AAMA 501.5</dd>
              <dd><b>결로 시험</b> By Spec</dd>
              <dd><b>기밀 성능 시험</b> ASTM E283</dd>
              <dd><b>수밀 성능 시험</b> ASTM E331, AAMA 501.1</dd>
              <dd><b>구조 성능 시험</b> ASTEM E330 / E330M</dd>
              <dd><b>수직 변위 시험</b> AAMA 501.7</dd>
              <dd><b>층간 변위 시험</b> AAMA 501.4</dd>
            </dl>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>
<script>

export default {
  name: 'CartridgeSkin',
  metaInfo() {
    return {
      title: 'FIT Platform',
      meta: [
        { vmid: 'title', content: 'FIT Platform', },
        { vmid: 'description', content: '플랫폼의 기반이 되는 FIT 프레임입니다. 건물의 외벽을 견고하게 구성하며 다양한 카트리지를 편리하게 설치하고 교체할 수 있도록 합니다.', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
@import "~@/less/prod.less";

[frame].prod {
  .hero {
    img { .w(155); }
  }
  .structure {
    img { .w(259); }
  }
}

@media (min-width: @screen-tp-min) {
  [frame].prod {
    .hero {
      img { .w(169); }
    }
    .structure {
      img { .w(335); }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [frame].prod {
    .hero {
      img { .w(233); }
    }
    .structure {
      img { .w(428); .max-w(45vh); }
      img + p { .w(660); }
    }
  }
}
@media (min-width: @screen-ds-min) {
  [frame].prod {
    .hero { .pt(160); }
    .hero {
      img { .w(255); }
    }
    .structure {
      img { .w(453); }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [frame].prod {
    .hero {
      img { .w(272); }
    }
    .structure {
      img { .w(453); }
    }
  }
}
</style>
